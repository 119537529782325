import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const sendSMS = (smsRequest) => {
  const urlPath = 'https://restapi.smscountry.com/v0.1/Accounts/IcDePIPhS82pdtmOFW86/SMSes/';
  const token =
    'SWNEZVBJUGhTODJwZHRtT0ZXODY6elc2Z1ptb2REQ0NKUXRmR3NOTWxCN21sdXhiSmpjc3JZSHIyZDRneA==';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: smsRequest,
    // Authorization: `Basic ${token}`,
    additionalHeader: {
      Authorization: `Basic ${token}`,
    },
  });
};
