import {
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  Alert,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
} from '@mui/material';
import { InputLabel } from '@mui/material';
import { Button, Grid, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';
import { addPatient, updatePatientDetails } from 'app/api-services/patient-ws';
import { getimageOptionDetails } from 'app/api-services/image-ws';
import { sendSMS } from 'app/api-services/smsCountry-ws';
import TeethSelector from 'app/views/TeethSelector';

import DoctorSearchable from '../doctorsearchable';
import { Validator } from 'app/utils/validator-utils';

Validator();

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const PatientForm = ({
  patientopd,
  doctorId,
  doctorDisabled,
  clinicDisabled,
  doctorName,
  clinicId,
  addFromClinic,
  patient,
  handleCloseDialog,
  showResendSMS,
  doctorList,
  token,
  onSelectDoctor,
}) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState(patient?.patientName?.firstName);
  const [lastName, setLastName] = useState(patient?.patientName?.lastName);
  const [gender, setGender] = useState(patient?.gender);
  const [phoneNumber, setPhoneNumber] = useState(patient?.patientPhone);
  const [age, setAge] = useState(patient?.age);
  const [patientId, setPatientId] = useState(patient?.patientId || patientopd);
  const [createdDate, setCreatedDate] = useState(moment(patient?.createdDate).format('YYYY-MM-DD'));
  // const [refDoctor, setRefferedDoctor] = useState(doctorName ? doctorName : patient?.refDoctorName);
  const [refDoctorId, setRefDoctorId] = useState(doctorId);
  const [image2D, setImage2D] = useState(patient?.image2D);
  const [image3D, setImage3D] = useState(patient?.image3D);
  const [description, setDescription] = useState(patient?.description);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [image2DList, setImage2DList] = useState([]);
  const [image3DList, setImage3DList] = useState([]);
  const [patientStatus, setPatientStatus] = useState(patient ? patient?.status : 'REFERRED');
  const [upperselectedTeeth, setUpperSelectedTeeth] = useState(patient?.upperTeethRegion || []);
  const [lowerselectedTeeth, setLowerSelectedTeeth] = useState(patient?.lowerTeethRegion || []);
  const [resendSMS, setResendSMS] = useState(false);
  const [refDoctor, setReferredDoctor] = useState(doctorName ? doctorName : patient?.refDoctorName);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  // const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleDoctorChange = (selectedDoctor) => {
    setSelectedDoctor(selectedDoctor);
    setReferredDoctor(selectedDoctor); // Update the state with the selected doctor
  };

  useEffect(() => {
    getimageOptionDetails(clinicId, token)
      .then((res) => {
        setImage2DList(res.data[0].digitalImages2D);
        setImage3DList(res.data[0].digitalImages3D);
      })
      .catch((err) => {
        setSnackBarMessage('Error in Loading Image List');
        setSnackBarType('error');
        showSnackBar();
      });
  }, []);

  var patientRequest = {};
  const img3d = image3D?.map((ele) => ele.sms).filter(Boolean) || [];
  const img2d = image2D?.map((img) => img.sms).filter(Boolean) || [];

  const img = img2d.concat(img3d).join(',');

  var smsRequest = {
    Text: `Hi ${firstName}, x-ray(${
      img ? img : ''
    })appointment scheduled at Dental Digital Imaging, 
    Ganesha Complex, 1st Floor, 
    Dental College Road, M.C.C 'B' Block, 
    Davangere - 4. 
    Maps link: https://maps.app.goo.gl/M2dhN9T7rHqB7Zoe9. 
    9448053148, 
    9449834148`,
    Number: `91${phoneNumber}`,
    SenderId: 'DEDIMG',
    DRNotifyUrl: 'https://www.domainname.com/notifyurl',
    DRNotifyHttpMethod: 'POST',
    Tool: 'API',
  };
  patientRequest = {
    patientName: {
      firstName: firstName,
      lastName: lastName,
    },
    age: age,
    createdDate: createdDate,
    description: description,
    gender: gender,
    patientPhone: phoneNumber,
    refDoctorId: refDoctorId,
    patientId: patientId,
    refDoctorName: refDoctor,
    refClinicId: clinicId,
    image2D: image2D,
    image3D: image3D,
    patientStatus: patientStatus,
    upperTeethRegion: upperselectedTeeth,
    lowerTeethRegion: lowerselectedTeeth,
  };
  var updatePatientRequest = {};
  updatePatientRequest = {
    _id: patient?._id,
    patientName: {
      firstName: firstName,
      lastName: lastName,
    },
    age: age,
    createdDate: createdDate,
    description: description,
    gender: gender,
    patientId: patientId,
    patientPhone: phoneNumber,
    refDoctorName: refDoctor,
    image2D: image2D,
    image3D: image3D,
    patientStatus: patientStatus,
    upperTeethRegion: upperselectedTeeth,
    lowerTeethRegion: lowerselectedTeeth,
  };
  //total x-ray price
  const totalServiceCost3D = patient?.image3D?.reduce(
    (total, image) => total + parseInt(image.serviceCost),
    0
  );
  const totalServiceCost2D = patient?.image2D?.reduce(
    (total, image) => total + parseInt(image.serviceCost),
    0
  );
  const totalServiceCost = isNaN(totalServiceCost3D)
    ? totalServiceCost2D
    : isNaN(totalServiceCost2D)
    ? totalServiceCost3D
    : totalServiceCost3D + totalServiceCost2D;

  const [price, setPrice] = useState(totalServiceCost);

  // sendSMS
  const sendPatientSMS = () => {
    sendSMS(smsRequest)
      .then((res) => {
        console.log(res);
        // if (res.data.status === 'success') {
        //   setSnackBarMessage('Clinic Added Successfully');
        //   setSnackBarType('success');
        //   showSnackBar();
        //   handleCloseDialog();
        // } else {
        //   setSnackBarMessage('Error in adding Patient');
        //   setSnackBarType('error');
        //   showSnackBar();
        // }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Patient');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const updatePatientSubmit = (event) => {
    console.log('in submit');
    updatePatientDetails(updatePatientRequest, token)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Patient Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Patient');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Patient');
        setSnackBarType('error');
        showSnackBar();
      });
    if (resendSMS) {
      sendPatientSMS();
    }
    console.log('submitted');
  };
  const handleSubmit = (event) => {
    console.log('in submit');
    addPatient(patientRequest, token)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Patient Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Patient');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Patient');
        setSnackBarType('error');
        showSnackBar();
      });
    if (!addFromClinic) {
      sendPatientSMS();
    }

    console.log('submitted');
  };

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <ValidatorForm
        onSubmit={patient ? updatePatientSubmit : handleSubmit}
        onError={(err) => {
          console.log(err);
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={2}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="FirstName"
              id="standard-basic"
              value={firstName || ''}
              onChange={(val) => {
                setFirstName(val.target.value);
              }}
              errorMessages={['this field is required']}
              label="Firstname"
              validators={['required']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="lastname"
              label="Lastname"
              onChange={(val) => {
                setLastName(val.target.value);
              }}
              value={lastName || ''}
              errorMessages={['Enter a valid Last Name']}
              validators={['isLastName']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="number"
              name="age"
              label="Age"
              value={age || ''}
              onChange={(val) => {
                setAge(val.target.value);
              }}
              validators={['required', 'isValidAge']}
              errorMessages={['This field is required', 'Age must be 1 year or more']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={doctorDisabled}
              type="number"
              name="patientId"
              label="Patient Id"
              value={patientId || ''}
              onChange={(val) => {
                setPatientId(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Gender</InputLabel>
              <Select
                name="gender"
                value={gender || ''}
                onChange={(val) => {
                  setGender(val.target.value);
                }}
                label="Gender"
                validators={['required']}
                errorMessages={['Please select a gender']}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="date"
              name="refDate"
              value={createdDate || ''}
              disabled={clinicDisabled || doctorDisabled}
              onChange={(val) => {
                console.log('date', val.target.val);
                setCreatedDate(val.target.value);
              }}
              validators={['required']}
              errorMessages={['this field is required']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {addFromClinic ? (
              doctorList ? (
                <Typography>
                  <DoctorSearchable
                    setReferredDoctor={setReferredDoctor}
                    doctorOptions={doctorList}
                    value={refDoctor}
                    setRefDoctorId={setRefDoctorId}
                    onChange={handleDoctorChange}
                    renderOption={(option) => <div>{`${option.firstName} ${option.lastName}`}</div>}
                  />
                </Typography>
              ) : null
            ) : clinicDisabled ? (
              <>
                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                  Referred Doctor
                </Typography>

                <Box border="1px solid #000" borderRadius="4px" padding="8px" marginBottom="16px">
                  <Typography style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                    {refDoctor}
                  </Typography>
                </Box>
              </>
            ) : (
              <TextField
                type="text"
                disabled={doctorDisabled || clinicDisabled}
                name="refDoctor"
                label="Referred Doctor"
                value={refDoctor || ''}
                onChange={(val) => setReferredDoctor(val.target.value)}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {clinicDisabled ? (
              <>
                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                  Phone Number
                </Typography>

                <Box border="1px solid #000" borderRadius="4px" padding="8px" marginBottom="16px">
                  <Typography style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                    {phoneNumber}
                  </Typography>
                </Box>
              </>
            ) : (
              <TextField
                type="text" // Use "text" type for the phone number
                name="phoneNumber"
                disabled={clinicDisabled}
                label="Phone Number"
                value={phoneNumber || ''}
                onChange={(val) => {
                  // Ensure that the entered value is numeric
                  const numericValue = val.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                  setPhoneNumber(numericValue);
                }}
                validators={['required', 'isNumber', 'isTenDigits', 'isNotStartingWithZero']} // Validate for 10 digits
                errorMessages={[
                  'This field is required',
                  'Must be a number',
                  'Phone number must be 10 digits',
                  'Phone number cannot start with 0',
                ]}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {clinicDisabled ? (
              <>
                <>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                    2D Image
                  </Typography>

                  <Box border="1px solid #000" borderRadius="4px" padding="8px" marginBottom="16px">
                    <Typography style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                      {image2D?.length === 0
                        ? 'No Image Option Selected'
                        : image2D?.map((val) => val.sms).join(', ')}
                    </Typography>
                  </Box>
                </>
              </>
            ) : (
              <Autocomplete
                multiple
                id="tags-outlined"
                options={image2DList}
                value={image2D}
                disabled={clinicDisabled}
                onChange={(_, val) => {
                  setImage2D(val);
                }}
                getOptionLabel={(option) => option.name}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="2D Images"
                    placeholder="Select 2D Images"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            {clinicDisabled ? (
              <>
                <>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                    3D Image
                  </Typography>
                  <Box border="1px solid #000" borderRadius="4px" padding="8px" marginBottom="16px">
                    <Typography style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                      {image3D?.length === 0
                        ? 'No Image Option Selected'
                        : image3D?.map((val) => val.sms).join(', ')}
                    </Typography>
                  </Box>
                </>
              </>
            ) : (
              <Autocomplete
                multiple
                id="tags-outlined"
                options={image3DList}
                disabled={clinicDisabled}
                value={image3D}
                onChange={(_, val) => {
                  setImage3D(val);
                }}
                getOptionLabel={(option) => option.name}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="3D Images"
                    placeholder="Select 3D Images"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="description"
              value={description || ''}
              label="Description"
              onChange={(val) => setDescription(val.target.value)}
            />
          </Grid>
          {clinicDisabled ? (
            <Grid item xs={6}>
              <TextField
                type="text"
                name="price"
                value={price || ''}
                label="Total Price in Rs.(₹)"
                onChange={(val) => setPrice(val.target.value)}
              />
            </Grid>
          ) : null}

          <Grid container>
            <TeethSelector
              disable={clinicDisabled}
              upperselectedTeeth={upperselectedTeeth}
              lowerselectedTeeth={lowerselectedTeeth}
              setUpperSelectedTeeth={setUpperSelectedTeeth}
              setLowerSelectedTeeth={setLowerSelectedTeeth}
            />
          </Grid>
        </Grid>
        {showResendSMS ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={resendSMS}
                onChange={(event) => {
                  setResendSMS(event.target.checked);
                }}
              />
            }
            label="Resend SMS"
          />
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>

          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {patient ? 'Save Changes' : 'Add Patient'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default PatientForm;
